import React from 'react';

import { wrapper, grid, container, help, close } from './result.module.scss';
import { ISection } from '../../models/section.model';
import { getUrlParamValue } from '../../utils/get-url-param-value';

import Section from '../hoc/section';
import AnimatedCheckmarkOutlined from '../atoms/animated-checkmark-outlined';
import Info from '../organisms/info';
import Markdown from '../hoc/markdown';
import IconFactory from '../hoc/icon-factory';

export interface ISectionResultProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

export default function Result({ section, TitleTag = 'h2' }: ISectionResultProps) {
    const { sectionId, content, css, style } = section;
    const [titleSuccess, textSuccess, helpTextSuccess, titleError, textError, helpTextError] =
        content.texts;
    const [buttonSuccess, buttonError] = content.buttons;

    const isError = !!getUrlParamValue('error');

    const title = isError ? titleError : titleSuccess;
    const text = isError ? textError : textSuccess;
    const helpText = isError ? helpTextError : helpTextSuccess;
    const button = isError ? buttonError : buttonSuccess;

    return (
        <Section
            sectionId={sectionId}
            className={wrapper}
            classes={{ container: grid }}
            css={css}
            style={style}
            roundedSection={true}
        >
            <Info className={container} title={title} button={button} TitleTag={TitleTag}>
                {({ checkmarkClassName, descriptionClassName }) => (
                    <>
                        {!isError && <AnimatedCheckmarkOutlined className={checkmarkClassName} />}
                        {isError && (
                            <div className={`${close} ${checkmarkClassName}`}>
                                <IconFactory icon="close" />
                            </div>
                        )}
                        <div className={descriptionClassName}>
                            {text && <Markdown>{text}</Markdown>}
                        </div>
                        {helpText && <Markdown className={help}>{helpText}</Markdown>}
                    </>
                )}
            </Info>
        </Section>
    );
}
