import React from 'react';

import { checkmarkOutlined } from './animated-checkmark-outlined.module.scss';
import CheckmarkOutlined from '../../assets/images/svg/checkmark-outlined.svg';

export interface IAnimatedCheckmarkOutlined {
    className?: string;
}

export default function AnimatedCheckmarkOutlined({ className = '' }: IAnimatedCheckmarkOutlined) {
    return <CheckmarkOutlined className={[checkmarkOutlined, className].join(' ')} />;
}
